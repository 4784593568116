import { NeoSecondaryButton, NeoPrimaryButton } from "@neo/neo-common-ui/lib"
import NeoErrorBoundary from "@neo/neo-common-ui/lib/components/neo-error-boundary"
import NeoThemeProvider from "@neo/neo-common-ui/lib/components/neo-theme/NeoThemeProvider"
import { Intl, messages } from "../../forcepoint-platform-utilityui"
import { useEffect } from "react"

const NeoPopup = (props) => {
  useEffect(() => {
    document.querySelector("body").classList.add("no-scroll")
    return () => {
      document.querySelector("body").classList.remove("no-scroll")
    }
  }, [])
  return (
    <NeoErrorBoundary>
      <NeoThemeProvider>
        <Intl.IntlProvider locale={"en"} messages={messages["en"]}>
          <div
            className={
              "fone-modal " +
              props.popupClass +
              " " +
              (props.modalOpenStatus ? "show" : "")
            }
          >
            <div className="fone-modal-overlay"></div>
            <div className="fone-modal-content">
              {!props.hideHeader ? (
                <div className="fone-modal-header">
                  <h2 className="fone-modal-title">{props.modalTitle}</h2>
                  {!props.hideCloseIcon && (<a
                    id="fone_modal_close"
                    className="fone-modal-close"
                    onKeyDown={() => { }}
                    onClick={() => {
                      props.setOpenModalStatus(false)
                    }}
                  >
                    &#x2715;
                  </a>)}
                </div>
              ) : null}
              <div
                className={`fone-modal-body ${!props.showFooter ? "with-no-footer" : ""}`}
              >
                {props.children}
              </div>
              {props.showFooter ? (
                <div className="fone-modal-footer">
                  {props.popupFooterBtnConfig.showNegativeBtn ? (
                    <>
                      <NeoSecondaryButton
                        id={`${props.popupFooterBtnConfig?.negativeBtnText}_negative_btn`}
                        onClick={() => {
                          props.popupFooterBtnConfig.negativeBtnAction()
                        }}
                      >
                        {props.popupFooterBtnConfig?.negativeBtnText}
                      </NeoSecondaryButton>
                      <span style={{ marginRight: "1rem" }}></span>
                    </>
                  ) : null}
                  {props.popupFooterBtnConfig?.positiveBtnText ? (
                    <NeoPrimaryButton
                      id={`${props.popupFooterBtnConfig?.positiveBtnText}_positive_btn`}
                      onClick={() => {
                        props.popupFooterBtnConfig.positiveBtnAction()
                      }}
                    >
                      {props.popupFooterBtnConfig?.positiveBtnText}
                    </NeoPrimaryButton>
                  ) : null}
                </div>
              ) : null}
            </div>
          </div>
        </Intl.IntlProvider>
      </NeoThemeProvider>
    </NeoErrorBoundary>
  )
}

export default NeoPopup
