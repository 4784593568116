import useNeoWindowSize from "@neo/neo-common-ui/lib/neo-hooks/useNeoWindowSize"
import styled from "styled-components"
import { NeoCenterFlex, NeoLayout } from "@neo/neo-common-ui/lib"

export const PageContainer = styled.div`
  display: flex;
  height: calc(100vh - var(--platform-header-height));
  width: 100%;
  overflow: hidden;
`

const MainPageDiv = styled.div<{
  width: string
  isOpen: boolean
}>`
  display: flex;
  flex-direction: column;
  width: ${({ isOpen, width }) => (isOpen ? width : "100%")};
  height: 100%;
  transition: width 0.5s ease-in-out;
`

export const MainPage = function (props) {
  const windowSize = useNeoWindowSize()
  const TenantDrawerWidth = windowSize.width < 1500 ? "650px" : props.width
  return (
    <MainPageDiv
      width={`calc(100% - ${TenantDrawerWidth})`}
      isOpen={props.isOpen}
    >
      {props.children}
    </MainPageDiv>
  )
}

export const Page = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  row-gap: 16px;
`

export const FormFooter = styled(NeoCenterFlex)`
  position: absolute;
  bottom: 0;
  right: 0;
  justify-content: flex-end !important;
  gap: 16px;
  width: 100%;
  padding: 16px 0;
  margin: 0 16px;
  background-color: #f9fafb;
`

export const Title = styled(NeoCenterFlex)`
  font-size: 14px;
  line-height: 16px;
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  justify-content: flex-start !important;
  color: ${({ theme }) => theme.neutral.darkSlate};
  background-color: rgb(255, 255, 255);
  font-weight: 700;
`

export const MenuButton = styled(NeoCenterFlex)<{
  ref?: any
}>`
  font-size: 12px;
  line-height: 14px;
  color: rgb(0, 175, 154);
  background-color: transparent;
  cursor: pointer;
  white-space: nowrap;
  padding: 2px 4px;
  border-radius: 4px;

  &:hover {
    background-color: rgb(177, 227, 219);
    color: rgb(61, 78, 92);
  }
`

export const PopoverContent = styled(NeoCenterFlex)`
  flex-flow: column;
  -webkit-box-pack: start;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 16px;
  background: rgb(255, 255, 255);
  border: 0.5px solid rgb(212, 220, 226);
  box-shadow: rgba(151, 170, 186, 0.12) 0px 0px 8px;
  border-radius: 6px;
  row-gap: 8px;
  max-height: 300px;
  overflow: overlay;
`

/* Popup Styles */
export const PopupMainContainer = styled(NeoCenterFlex)<{ wrap }>`
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 16px;
  background: ${({ theme }) => theme.neutral.white};
  border: 0.5px solid ${({ theme }) => theme.neutral.powderBlue};
  box-shadow: ${({ theme }) => theme.shadow.s5};
  border-radius: 6px;
  row-gap: 8px;
  ${({ wrap }) => (wrap ? "width: 200px;" : "")}
  max-height: 300px;
  ${({ theme }) => theme.scrollbar};
`

export const Container = styled(NeoCenterFlex)`
  justify-content: flex-start;
  column-gap: 8px;
  width: 100%;
`

export const TruncateContainer = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: fit-content;
`

export const TextContainer = styled(TruncateContainer)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: fit-content;
  margin: 5px 0px;
`

export const MoreContainer = styled(NeoCenterFlex)`
  justify-content: flex-start;
`

export const MoreCell = styled(NeoCenterFlex)<{
  isActive: boolean
}>`
  font-size: 12px;
  line-height: 14px;
  color: ${({ theme, isActive }) =>
    isActive ? theme.neutral.lightSlate : theme.primaryBrand.teal400};
  background-color: ${({ theme, isActive }) =>
    isActive ? theme.primaryBrand.teal100 : "transparent"};
  cursor: pointer;
  white-space: nowrap;
  padding: 2px 4px;
  border-radius: 4px;

  &:hover {
    background-color: ${({ theme }) => theme.primaryBrand.teal100};
    color: ${({ theme }) => theme.neutral.lightSlate};
  }
`

export const ItemCell = styled(NeoCenterFlex)<{
  color: string
}>`
  margin-right: 5px;
  background: ${({ color }) => color}; //'rgb(225, 240, 247)'
  border-radius: 4px;
  column-gap: 4px;
  padding: 0 4px;
  white-space: nowrap;
  overflow: hidden;
  justify-content: flex-start;
  min-height: 20px;
`

export const ItemCellText = styled.span<{ wrap }>`
  font-size: 12px;
  color: ${({ theme }) => theme.neutral.lightSlate};
  ${({ wrap }) =>
    wrap
      ? `
      word-break: break-all;
      text-wrap: wrap;
     `
      : `
     line-height: 20px;
     text-overflow: ellipsis;
     display: block;
     white-space: nowrap;
     overflow: hidden;
     `}
`

export const ClosedContainer = styled(NeoCenterFlex)`
  column-gap: 8px;
  margin: 8px 16px;
  min-height: 24px;
  justify-content: flex-start !important;
`

export const ClosedContainerLabel = styled(NeoCenterFlex)`
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme?.neutral?.lightSlate};
  font-weight: ${({ theme }) => theme?.fontWeight?.medium};
  overflow: inherit;
  white-space: nowrap;
`

export const DeviceRuleOpenedSelectLabel = styled(NeoCenterFlex)`
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme?.neutral?.blueGray};
  overflow: inherit;
`

export const ClosedContainerText = styled(TruncateContainer)<{
  maxWidth?: string
}>`
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme?.neutral?.lightSlate};
  max-width: ${({ maxWidth }) => maxWidth || "fit-content"};
`

export const DeviceRuleOpenedContainer = styled(NeoCenterFlex)<{
  rowGap?: string
}>`
  width: 100%;
  align-items: flex-start;
  flex-flow: column;
  padding: 16px;
  row-gap: ${({ rowGap }) => rowGap || "16px"};
`

export const ScrollableContent = styled.div<{ padding?: string }>`
  width: 100%;
  overflow: auto;
  padding: ${({ padding }) => padding || "16px"};
  margin-bottom: 60px;
  ${({ theme }) => theme.scrollbar};
`

export const SpecificPanel = styled(DeviceRuleOpenedContainer)<{
  isHidden?: boolean
}>`
  height: fit-content;
  max-height: ${({ isHidden }) => (isHidden ? 0 : "1000px")};
  transition: max-height 0.25s ease-in-out;
  overflow: hidden;
  padding: 0;
`

export const BlackRedTag = styled(NeoCenterFlex)<{
  isError?: boolean
  visible?: boolean
}>`
  display: ${({ visible = true }) => (visible ? "flex" : "none")};
  padding: 0 8px;
  background: ${({ theme, isError = false }) =>
    isError ? "#FDECEE" : theme?.neutral?.lightSlate};
  border-radius: 16px;
  font-size: 12px;
  line-height: 24px;
  color: ${({ theme, isError = false }) =>
    isError ? theme?.status?.critical : theme?.neutral?.white};
`

export const ListItemContainer = styled(NeoLayout)<{
  level: number
  isSelected: boolean
}>`
  width: 100%;
  height: 46px;
  min-height: 46px;
  padding-left: ${({ level }) => level * 16}px;
  padding-right: 16px;
  cursor: pointer;
  border-bottom: ${({ level }) =>
    level === 1 ? "0.5px solid #F3F5F7" : "none"};
  :hover {
    background-color: ${({ theme, isSelected }) =>
      isSelected ? theme.neutral.brightGray : theme.neutral.whiteGray};
  }
`

export const ReadOnlyClosedContainer = styled(NeoCenterFlex)`
  column-gap: 8px;
  margin: 8px 16px;
  min-height: 24px;
  justify-content: start !important;
`

export const ReadOnlyClosedContainerLabel = styled(TruncateContainer)`
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.neutral.lightSlate};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  overflow: inherit;
  white-space: nowrap;
  font-weight: bold;
`

export const ReadOnlyClosedContainerText = styled(NeoCenterFlex)<{
  maxWidth?: string
}>`
  word-break: break-all;
  text-wrap: wrap;
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.neutral.lightSlate};
  max-width: ${({ maxWidth }) => maxWidth || "fit-content"};
`

export const CustomFormFooter = styled(NeoCenterFlex)`
  justify-content: flex-end;
  padding: 16px 16px;
  margin: 0 -16px -16px -16px;
  gap: 16px;
  background-color: #f9fafb;
`

export const Section = styled(NeoCenterFlex)`
  padding: 16px 16px 32px;
  background: ${({ theme }) => theme.neutral.white};
  border: 1px solid ${({ theme }) => theme.neutral.paleGray};
  border-radius: 8px;
  row-gap: 16px;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
`

export const Entry = styled(NeoCenterFlex)`
  width: 100%;
  justify-content: space-between;
`

export const EntryGrid = styled.div<{ visible?: boolean }>`
  width: 100%;
  display: ${({ visible = true }) => (visible ? "grid" : "none")};
  grid-template-columns: 1fr 1fr;
`

export const Label = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 22px;
  color: ${({ theme }) => theme.neutral.cloudGray};
`

export const CustomLabel = styled.div`
  align-items: center;
  font-size: 12px;
  line-height: 14px;
  height: 14px;
  margin-bottom: 2px;
  color: #3d4e5c;
`

export const CustomErrorMessage = styled.div`
  align-items: center;
  font-size: 12px;
  line-height: 14px;
  height: 14px;
  color: #ff3c1c;
`

export const Text = styled.div<{ bold?: boolean }>`
  font-size: 13px;
  line-height: 24px;
  font-weight: ${({ theme, bold = false }) =>
    bold ? theme.fontWeight.medium : theme.fontWeight.regular};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: fit-content;
`

export const ItemBox = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
`

export const TitleBox = styled.div`
  font-size: 12px;
  line-height: 15px;
  color: ${({ theme }) => theme.neutral.blueGray};
  padding-bottom: 4px;
  padding-right: 4px;
  white-space: nowrap;
`

export const ValueContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
`

export const DefaultDeviceSummaryBox = styled(NeoCenterFlex)`
  align-items: center;
  justify-content: flex-start;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.neutral.paleGray};
  padding: 8px;
  position: relative;
`

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  width: 100%;
`

/**
 * To store table header styling
 */
export const Table_Header_Styling = {
  whiteSpace: "nowrap",
  maxWidth: "300px",
  overflow: "hidden",
  textOverflow: "ellipsis"
}
