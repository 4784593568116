import styled from "styled-components"
import { useEffect, useState } from "react"
import {
  NeoIcon,
  NeoSecondaryButton,
  NeoSelectWithSearch
} from "@neo/neo-common-ui/lib"
import { ButtonGroup } from "../InlineDrawer/StyleComponents"
import NeoErrorBoundary from "@neo/neo-common-ui/lib/components/neo-error-boundary"
import NeoThemeProvider from "@neo/neo-common-ui/lib/components/neo-theme/NeoThemeProvider"
import { Intl, messages } from "../../forcepoint-platform-utilityui"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  gap: 8px;
`

export function Dropdown(props) {
  const [filterVal, setFilterVal] = useState(null)
  const filterKey = props.filterInfo?.columnKey;

  const clearFilter = () => {
    setFilterVal("")
    props.onChangeCallback({ [filterKey]: undefined })
    props.closeCallback()
  }

  useEffect(() => {
    const val = props?.filterInfo?.additionalInfo?.value
    if (props?.filterInfo?.additionalInfo?.value) {
      setFilterVal({
        text: val,
        value: val
      })
    }
  }, [props?.filterInfo?.additionalInfo?.value])

  return (
    <NeoErrorBoundary>
      <NeoThemeProvider>
        <Intl.IntlProvider locale={"en"} messages={messages["en"]}>
          <div className="table-custom-filter">
            <Container>
              <div className="select-filter">
                {props?.filterInfo?.additionalInfo?.type === "dropdown" ? (
                  <div className="d-flex flex-column align-items-center input-suffix">
                    <NeoSelectWithSearch
                      id="dropdown-filter"
                      placeholder="Search"
                      dropListDistance={8}
                      dropListSkidding={0}
                      onChange={(event) => {
                        if (event.value === "") {
                          clearFilter()
                        } else {
                          setFilterVal(event)
                          props.onChangeCallback({
                            [filterKey]: event.value
                          })
                          props.closeCallback()
                        }
                      }}
                      optionList={
                        props?.filterInfo?.additionalInfo?.filterList || []
                      }
                      selectedOption={filterVal}
                    />

                    {props?.filterInfo?.additionalInfo?.desc && (
                      <div className="info-tooltip">
                        <NeoIcon
                          fill="#00af9a"
                          name="outline_info"
                          data-always="true"
                          data-font-size="16px"
                          data-dark-html-tip={
                            props.filterInfo.additionalInfo.desc
                          }
                          data-dark-html-tip-at="left"
                          data-line-height="18px"
                          data-margin="2px"
                          data-padding="8px 12px"
                          data-white-space="nowrap"
                          data-word-break="normal"
                          onClick={function noRefCheck() { }}
                          size={12}
                        />
                      </div>
                    )}
                  </div>
                ) : null}
              </div>
              <ButtonGroup>
                <NeoSecondaryButton onClick={() => clearFilter()}>
                  Clear
                </NeoSecondaryButton>
              </ButtonGroup>
            </Container>
          </div>
        </Intl.IntlProvider>
      </NeoThemeProvider>
    </NeoErrorBoundary>
  )
}
