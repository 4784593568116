import styled from "styled-components"
import { useEffect, useState } from "react"
import {
  NeoIcon,
  NeoInput,
  NeoPrimaryButton,
  NeoSecondaryButton
} from "@neo/neo-common-ui/lib"
import NeoErrorBoundary from "@neo/neo-common-ui/lib/components/neo-error-boundary"
import NeoThemeProvider from "@neo/neo-common-ui/lib/components/neo-theme/NeoThemeProvider"
import { Intl, messages } from "../../forcepoint-platform-utilityui"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  gap: 8px;
`

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  width: 100%;
`

export function TextFilterComponent(props) {
  const [filterVal, setFilterVal] = useState("")

  useEffect(() => {
    if (props?.filterInfo?.additionalInfo?.value) {
      setFilterVal(props.filterInfo.additionalInfo.value)
    }
  }, [props?.filterInfo?.additionalInfo?.value])

  const filterKey = props.filterInfo?.columnKey;
  const updateSelectedFilter = () => {
    if (filterVal && props.onChangeCallback) {
      props.onChangeCallback({ [filterKey]: filterVal })
      props.closeCallback()
    }
  }
  const clearFilter = () => {
    setFilterVal("")
    props.onChangeCallback({ [filterKey]: undefined })
    props.closeCallback()
  }

  return (
    <NeoErrorBoundary>
      <NeoThemeProvider>
        <Intl.IntlProvider locale={"en"} messages={messages["en"]}>
          <div className="table-custom-filter">
            <Container>
              <div>
                {props?.filterInfo?.additionalInfo?.type === "text" ? (
                  <div
                    className={`d-flex align-items-center ${props?.filterInfo?.additionalInfo?.desc ? "input-suffix" : ""}`}
                  >
                    <NeoInput
                      {...{
                        id: filterKey,
                        placeholder:
                          "Enter " + props.filterInfo.additionalInfo?.label,
                        containerStyle: { color: "#29343D" },
                        type: "text",
                        maxLength: 128,
                        label: props.filterInfo.additionalInfo?.label + ":",
                        value: filterVal,
                        onChange: (event) => setFilterVal(event.value)
                      }}
                    />
                    {props?.filterInfo?.additionalInfo?.desc && (
                      <div className="info-tooltip">
                        <NeoIcon
                          fill="#00af9a"
                          name="outline_info"
                          data-always="true"
                          data-font-size="16px"
                          data-dark-html-tip={
                            props.filterInfo.additionalInfo.desc
                          }
                          data-dark-html-tip-at="left"
                          data-line-height="18px"
                          data-margin="2px"
                          data-padding="8px 12px"
                          data-white-space="nowrap"
                          data-word-break="normal"
                          onClick={function noRefCheck() { }}
                          size={12}
                        />
                      </div>
                    )}
                  </div>
                ) : null}
              </div>
              <ButtonGroup>
                <NeoSecondaryButton
                  onClick={() => clearFilter()}
                  isDisabled={!props?.filterInfo?.additionalInfo?.value}
                >
                  Clear
                </NeoSecondaryButton>
                <NeoPrimaryButton
                  onClick={() => updateSelectedFilter()}
                  isDisabled={!filterVal || filterVal.length === 0}
                >
                  Apply
                </NeoPrimaryButton>
              </ButtonGroup>
            </Container>
          </div>
        </Intl.IntlProvider>
      </NeoThemeProvider>
    </NeoErrorBoundary>
  )
}
