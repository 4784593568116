import styled from "styled-components"
import { useEffect, useState } from "react"
import {
  NeoCheckbox,
  NeoPrimaryButton,
  NeoSecondaryButton
} from "@neo/neo-common-ui/lib"
import NeoErrorBoundary from "@neo/neo-common-ui/lib/components/neo-error-boundary"
import NeoThemeProvider from "@neo/neo-common-ui/lib/components/neo-theme/NeoThemeProvider"
import { Intl, messages } from "../../forcepoint-platform-utilityui"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  gap: 8px;
`

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  width: 100%;
  margin-top: 1rem;
`

const initialState = { initialLoad: true, emptyList: true, list: [] }

export function CheckboxFilterComponent(props) {
  const [checkboxList, setCheckboxList] = useState<any>(initialState)

  useEffect(() => {
    return () => {
      setCheckboxList(initialState)
    }
  }, [])

  useEffect(() => {
    const filters = props?.filterInfo?.additionalInfo?.checkboxList
    if (filters) {
      const listData = {
        list: filters,
        isEmptyList: filters?.length === 0,
        initialLoad: false
      }
      setCheckboxList(listData)
    }
  }, [props?.filterInfo?.additionalInfo?.checkboxList])

  const filterKey = props.filterInfo?.columnKey;
  return (
    <NeoErrorBoundary>
      <NeoThemeProvider>
        <Intl.IntlProvider locale={"en"} messages={messages["en"]}>
          <div className="table-custom-filter">
            <Container>
              <div className="checkbox-filter">
                {props?.filterInfo?.additionalInfo?.type === "checkbox" ? (
                  <div>
                    {checkboxList?.list?.map((a, index) => (
                      <NeoCheckbox
                        label={a.value}
                        checked={a.isChecked}
                        onChange={(e) => {
                          const filtersData = [...checkboxList.list]
                          const currentFilter = filtersData.findIndex(
                            (b) => b.key === a.key
                          )
                          const updatedCurrentFilter = {
                            ...filtersData[currentFilter],
                            isChecked: e.target.checked
                          }
                          filtersData[currentFilter] = updatedCurrentFilter
                          const listData = {
                            list: filtersData,
                            isEmptyList: filtersData?.length === 0,
                            initialLoad: false
                          }
                          setCheckboxList(listData)
                        }}
                      />
                    ))}
                    <ButtonGroup>
                      <NeoSecondaryButton
                        onClick={() => {
                          if (props.onChangeCallback) {
                            props.onChangeCallback({
                              [filterKey]: ""
                            })
                            props.closeCallback()
                          }
                        }}
                        isDisabled={!props?.filterInfo?.additionalInfo?.value}
                      >
                        Clear
                      </NeoSecondaryButton>
                      <NeoPrimaryButton
                        onClick={() => {
                          if (props.onChangeCallback) {
                            const selectedValues = checkboxList?.list?.reduce(
                              (a, b) => {
                                if (b.isChecked) {
                                  a.push(b.key)
                                }
                                return a
                              },
                              []
                            )
                            props.onChangeCallback({
                              [filterKey]: selectedValues
                            })
                            props.closeCallback()
                          }
                        }}
                      >
                        Apply
                      </NeoPrimaryButton>
                    </ButtonGroup>
                  </div>
                ) : null}
              </div>
            </Container>
          </div>
        </Intl.IntlProvider>
      </NeoThemeProvider>
    </NeoErrorBoundary>
  )
}
