import React, { useEffect, useMemo, useState } from "react"
import styled, { css, keyframes, useTheme } from "styled-components"
import {
  NeoButtonPopup,
  NeoCenterFlex,
  NeoHBox,
  NeoIcon
} from "@neo/neo-common-ui/lib"
import {
  NeoPopupPosition,
  useNeoButtonPopupContext
} from "@neo/neo-common-ui/lib/components/neo-popover/NeoButtonPopup"
import { TruncateContainer } from "./GenericTableComponents"
import { NeoThemeType } from "@neo/neo-common-ui/lib/components/neo-theme/NeoTheme"
import NeoErrorBoundary from "@neo/neo-common-ui/lib/components/neo-error-boundary"
import NeoThemeProvider from "@neo/neo-common-ui/lib/components/neo-theme/NeoThemeProvider"
import { Intl, messages } from "../../forcepoint-platform-utilityui"

// =============== Styled Components ===============

const spinAnim = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
`

const Spin = styled(NeoCenterFlex)<{ isRefresh?: boolean }>`
  ${({ isRefresh }) =>
    isRefresh &&
    css`
      animation: ${spinAnim} 2s linear infinite;
    `};
`

const Clickable = styled(NeoCenterFlex)<{
  disabled: boolean
}>`
  ${({ disabled }) =>
    !disabled &&
    css`
      &:hover {
        background: ${({ theme }) => theme.neutral.paleGray};
        cursor: pointer;
      }
      &:active {
        background: ${({ theme }) => theme.neutral.brightGray};
        cursor: pointer;
      }
    `}
`

const PaginationBar = styled.div`
  height: 38px;
  display: flex;
  background: ${({ theme }) => theme.neutral.white};
  border-radius: 0 0 8px 8px;
  font-size: 14px;
  line-height: 14px;
  color: ${({ theme }) => theme.neutral.blueGray};
`

const ItemsInfoContainer = styled.div`
  color: ${({ theme }) => theme.neutral.blueGray};
  min-width: fit-content;
  display: flex;
  align-items: center;
  //border-right: solid 1px ${({ theme }) => theme.neutral.powderBlue};
`

const SizeSelectionContainer = styled.div`
  height: 100%;
  width: fit-content;
  display: flex;
  align-items: center;
  padding: 0 16px;
  gap: 8px;
  border-right: solid 1px ${({ theme }) => theme.neutral.powderBlue};
`

const PageInfoContainer = styled(TruncateContainer)`
  display: flex;
  align-items: center;
  padding-right: 12px;
  padding-left: 20px;
  font-weight: ${({ theme }) => theme.fontWeight.regular};
`

const ButtonContainer = styled(Clickable)`
  height: 38px;
  padding-right: 6px;
`

const PopupContainer = styled(NeoCenterFlex)`
  flex-direction: column;
  background: ${({ theme }) => theme.neutral.white};
  border: 0.5px solid ${({ theme }) => theme.neutral.powderBlue};
  box-shadow: 0 0 8px rgba(151, 170, 186, 0.12);
  border-radius: 4px;
`

const PageSize = styled.div`
  padding: 0 6px;
`

const SizeItem = styled(Clickable)<{
  selected: boolean
}>`
  background: ${({ theme, selected }) =>
    selected ? theme.neutral.brightGray : theme.neutral.white};
  width: 50px;
  height: 25px;
`

const NavigationItem = styled(Clickable)`
  width: 38px;
  height: 38px;
  border-left: solid 1px ${({ theme }) => theme.neutral.powderBlue};
`

const ItemsInfo = styled(TruncateContainer)`
  width: 160px;
  padding: 12px 16px;
  border-right: solid 1px ${({ theme }) => theme.neutral.powderBlue};
`

const SelectedItems = styled(NeoCenterFlex)<{
  isVisible: boolean
}>`
  display: ${({ isVisible }) => (isVisible ? "block" : "none")};
  padding: 12px 16px;
  border-right: solid 1px ${({ theme }) => theme.neutral.powderBlue};
`

const RefreshButton = styled(NeoCenterFlex)`
  width: 38px;
  height: 100%;
  background-color: ${({ theme }) => theme.neutral.white};
  border-right: solid 1px ${({ theme }) => theme.neutral.powderBlue};
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.neutral.paleGray};

    &:active {
      background-color: ${({ theme }) => theme.neutral.brightGray};
    }
  }
`

const NotificationWrapper = styled(NeoHBox)`
  overflow: hidden;
  flex: 1;
`

const Notification = styled(NeoCenterFlex)<{
  show: boolean
  duration?: number
}>`
  width: max-content;
  --offset: ${({ show = false }) => (show ? "0" : "-200%")};
  --duration: ${({ duration = 1000 }) => `${duration}ms`};
  transform: translateX(var(--offset));
  transition: all var(--duration) ease-in-out;
  z-index: 1;
  font-size: 16px;
  line-height: 18px;
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  border-radius: 8px;
  padding: 0 16px;
  background: ${({ theme }) => theme.neutral.lightGray};
`

// ================= Components =================

const PageSizeSelectorBtn: React.FC<{ size: number }> = React.memo(
  ({ size }) => {
    const ctx = useNeoButtonPopupContext()
    const theme = useTheme() as NeoThemeType

    const blueGray = theme.neutral.blueGray

    return (
      <ButtonContainer id="table_page_size_selector" disabled={false}>
        <PageSize>{size}</PageSize>
        <NeoIcon
          name={ctx.isOpen ? "carat-down" : "carat-up"}
          size={8}
          fill={blueGray}
        />
      </ButtonContainer>
    )
  }
)

const PageSizeSelectorPopup: React.FC<{
  pageSizes: number[]
  selectCallback: (newPageSize: number) => void
  selectedPageSize: number
}> = React.memo(({ pageSizes, selectCallback, selectedPageSize }) => {
  const ctx = useNeoButtonPopupContext()
  const sizesArr = pageSizes?.length > 0 ? pageSizes : [25]

  return (
    <PopupContainer>
      {sizesArr.map((size, index) => (
        <SizeItem
          id={`pageSize_item_${size}`}
          key={`pageSize_item_${index}`}
          onClick={(e) => {
            e.stopPropagation()
            selectCallback && selectCallback(size)
            ctx && ctx.closePopup()
          }}
          selected={size === selectedPageSize}
          disabled={false}
        >
          {size}
        </SizeItem>
      ))}
    </PopupContainer>
  )
})

// SizeSelectionPagination Component
//-------------------------------------------------------

const SizeSelectionPagination: React.FC<{
  pageSize: number
  pageIndex: number
  totalItems: number
  pageChangeHandler: (newPageIndex: number) => void
  pageSizeChangeHandler: (newPageSize: number) => void
  selectedItems?: number
  refreshCallback?: () => void
  isLoading?: boolean
  notification?: string
  notificationAnimationDuration?: number
  pageSizeList?: number[]
}> = ({
  pageSize,
  pageIndex,
  totalItems = 0,
  pageChangeHandler,
  pageSizeChangeHandler,
  selectedItems,
  refreshCallback,
  isLoading = false,
  notification,
  notificationAnimationDuration = 1000,
  pageSizeList = [25, 50, 100, 200]
}) => {
  const theme = useTheme() as NeoThemeType
  const [message, setMessage] = useState<string | undefined>(undefined)

  useEffect(() => {
    if (notification) {
      setMessage(notification)
    }
  }, [notification])

  const lightSlate = theme.neutral.lightSlate

  const { firstRowInPage, lastRowInPage, numOfPages } = useMemo(() => {
    const firstRowInPage = pageSize * pageIndex + 1
    let lastRowInPage = firstRowInPage + pageSize - 1
    if (lastRowInPage > totalItems) {
      lastRowInPage = totalItems
    }
    const numOfPages =
      Math.floor(totalItems / pageSize) + (totalItems % pageSize === 0 ? 0 : 1)

    return { firstRowInPage, lastRowInPage, numOfPages }
  }, [pageIndex, pageSize, totalItems])

  return (
    <NeoErrorBoundary>
      <NeoThemeProvider>
        <Intl.IntlProvider locale={"en"} messages={messages["en"]}>
          {totalItems > 0 ? (
            <PaginationBar>
              <ItemsInfoContainer>
                <ItemsInfo
                  data-dark-tip={`${firstRowInPage.toLocaleString()} - ${lastRowInPage.toLocaleString()} of ${totalItems.toLocaleString()} items`}
                >
                  {`${firstRowInPage.toLocaleString()} - ${lastRowInPage.toLocaleString()} of ${totalItems.toLocaleString()} items`}
                </ItemsInfo>
                {refreshCallback && (
                  <RefreshButton
                    id="table_refresh_btn"
                    onClick={refreshCallback}
                  >
                    <Spin isRefresh={isLoading}>
                      <NeoIcon
                        name={"load_refresh"}
                        size={14}
                        fill={theme.neutral.lightSlate}
                      />
                    </Spin>
                  </RefreshButton>
                )}
                {selectedItems && (
                  <SelectedItems
                    isVisible={!!selectedItems && selectedItems > 0}
                  >
                    {`${selectedItems} Selected`}
                  </SelectedItems>
                )}
              </ItemsInfoContainer>
              <NotificationWrapper>
                <Notification
                  show={!!notification}
                  duration={notificationAnimationDuration}
                >
                  {message}
                </Notification>
              </NotificationWrapper>
              <SizeSelectionContainer>
                <TruncateContainer style={{ lineHeight: "1.3rem" }}>
                  {"Rows per page:"}
                </TruncateContainer>
                <NeoButtonPopup
                  buttonComponent={<PageSizeSelectorBtn size={pageSize} />}
                  popupComponent={
                    <PageSizeSelectorPopup
                      pageSizes={pageSizeList}
                      selectedPageSize={pageSize}
                      selectCallback={pageSizeChangeHandler}
                    />
                  }
                  popupPosition={NeoPopupPosition.AUTO}
                />
              </SizeSelectionContainer>
              <PageInfoContainer>
                {`Page ${(pageIndex + 1).toLocaleString()} of ${numOfPages.toLocaleString()}`}
              </PageInfoContainer>
              <NavigationItem
                id="next"
                disabled={pageIndex <= 0}
                onClick={() =>
                  pageIndex > 0 && pageChangeHandler(pageIndex - 1)
                }
              >
                <NeoIcon
                  name="nav_caret"
                  size={8}
                  fill={pageIndex <= 0 ? "" : lightSlate}
                  style={{ transform: "rotate(180deg)" }}
                />
              </NavigationItem>
              <NavigationItem
                id="prev"
                disabled={pageIndex >= numOfPages - 1}
                onClick={() =>
                  pageIndex < numOfPages - 1 && pageChangeHandler(pageIndex + 1)
                }
              >
                <NeoIcon
                  name="nav_caret"
                  size={8}
                  fill={pageIndex >= numOfPages - 1 ? "" : lightSlate}
                />
              </NavigationItem>
            </PaginationBar>
          ) : (
            <PaginationBar>
              <RefreshButton id="table_refresh_btn" onClick={refreshCallback}>
                <Spin isRefresh={isLoading}>
                  <NeoIcon
                    name={"load_refresh"}
                    size={14}
                    fill={theme.neutral.lightSlate}
                  />
                </Spin>
              </RefreshButton>
            </PaginationBar>
          )}
        </Intl.IntlProvider>
      </NeoThemeProvider>
    </NeoErrorBoundary>
  )
}

export default React.memo(SizeSelectionPagination)
