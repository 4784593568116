import TagsInput from "react-tagsinput"
interface ReusableTagsInputProps {
    value: string[]
    disabled?: boolean
    inputValue?: string
    onChangeInput?: (input: string) => void
    onChange: (tags: string[]) => void
    placeholder?: string
    onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
    [key: string]: any // To allow other props like className, id, etc.
}

const ReusableTagsInput: React.FC<ReusableTagsInputProps> = ({
    value,
    disabled,
    inputValue,
    onChangeInput,
    onChange,
    placeholder,
    onBlur,
    ...rest
}: any) => {
    return (
        <TagsInput
            inputProps={{
                placeholder,
                onBlur
            }}
            value={value}
            disabled={disabled}
            inputValue={inputValue}
            onChangeInput={onChangeInput}
            onChange={onChange}
            renderTag={(renderProps) => {
                const {
                    tag,
                    key,
                    disabled,
                    onRemove,
                    classNameRemove,
                    getTagDisplayValue,
                    ...other
                } = renderProps
                return (
                    <span key={key} {...other}>
                        {getTagDisplayValue(tag)}
                        {!disabled && (
                            <a
                                onKeyDown={() => { }}
                                className={classNameRemove}
                                onClick={(e) => onRemove(key as any)}
                            >
                                <svg
                                    width="12"
                                    height="12"
                                    viewBox="0 0 12 12"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M0 6C0 2.68629 2.68629 0 6 0C9.31371 0 12 2.68629 12 6C12 9.31371 9.31371 12 6 12C2.68629 12 0 9.31371 0 6ZM8.8053 4.14389C9.06698 3.88221 9.06698 3.45794 8.8053 3.19626C8.54362 2.93458 8.11935 2.93458 7.85767 3.19626L6.00078 5.05315L4.14389 3.19626C3.88221 2.93458 3.45794 2.93458 3.19626 3.19626C2.93458 3.45794 2.93458 3.88221 3.19626 4.14389L5.05315 6.00078L3.19626 7.85767C2.93458 8.11935 2.93458 8.54362 3.19626 8.8053C3.45794 9.06698 3.88221 9.06698 4.14389 8.8053L6.00078 6.94841L7.85767 8.8053C8.11935 9.06698 8.54362 9.06698 8.8053 8.8053C9.06698 8.54362 9.06698 8.11935 8.8053 7.85767L6.94841 6.00078L8.8053 4.14389Z"
                                        fill="#8FAFBB"
                                    />
                                </svg>
                            </a>
                        )}
                    </span>
                )
            }}
            {...rest}
        />
    )
}

export default ReusableTagsInput
