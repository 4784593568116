import styled from "styled-components"
import { useEffect, useState } from "react"
import {
  NeoPrimaryButton,
  NeoSecondaryButton,
  NeoSingleSelectList
} from "@neo/neo-common-ui/lib"
import { isEmpty } from "../../app.constants"
import { ButtonGroup } from "../InlineDrawer/StyleComponents"
import CardLoader from "../CardLoader/CardLoader"
import NeoErrorBoundary from "@neo/neo-common-ui/lib/components/neo-error-boundary"
import NeoThemeProvider from "@neo/neo-common-ui/lib/components/neo-theme/NeoThemeProvider"
import { Intl, messages } from "../../forcepoint-platform-utilityui"
import EmptyComponent from "./EmptyComponent"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  gap: 8px;
`

export function SelectedFilterComponent(props) {
  /**
   * To store and set filter list
   */
  const [filterList, setFilterList] = useState<any[]>([])

  /**
   * To store and set selected filter
   */
  const [selectedFilter, setSelectedFilter] = useState([])

  /**
   * To set and show search loader status
   */
  const [showSearchLoader, setShowSearchLoader] = useState<boolean>(false)

  /**
   * On Destroy Effect
   */
  useEffect(() => {
    return () => {
      setFilterList([])
    }
  }, [])

  /**
   * Props filter list effect
   */
  useEffect(() => {
    let filters = props?.filterInfo?.additionalInfo?.filterList || []
    if (!isEmpty(props?.filterInfo?.additionalInfo?.value)) {
      const selectedValue = props?.filterInfo?.additionalInfo?.value
      const initialSelectList =
        typeof selectedValue === "string"
          ? selectedValue.split(",")
          : selectedValue
      const selectedFilters =
        initialSelectList?.map((a) => ({ text: a, value: a })) || []
      filters = isEmpty(filters) ? selectedFilters : filters
      setSelectedFilter(selectedFilters)
    }
    setFilterList(filters)
    setShowSearchLoader(false)
  }, [props?.filterInfo?.additionalInfo?.filterList])


  const filterKey = props.filterInfo?.columnKey;
  /**
   * UI Template
   */
  return (
    <NeoErrorBoundary>
      <NeoThemeProvider>
        <Intl.IntlProvider locale={"en"} messages={messages["en"]}>
          <div className="table-custom-filter">
            <Container>
              <div className="select-filter">
                <div className="d-flex flex-column align-items-center">
                  <NeoSingleSelectList
                    items={filterList}
                    selected={selectedFilter}
                    alwaysShowSearchBar={true}
                    searchDebounceTime={800}
                    shouldFilterItems={false}
                    onSearch={(e) => {
                      if (e !== undefined) {
                        setSelectedFilter([])
                        setFilterList([])
                        setShowSearchLoader(true)
                        props.onChangeCallback({
                          [filterKey]: e,
                          searchValue: true
                        })
                      }
                    }}
                    onItemClicked={(val: any) => {
                      const selectedFilterValueData = [...selectedFilter]
                      const valIndex = selectedFilterValueData.findIndex(
                        (a) => a.value === val.value
                      )
                      if (valIndex !== -1) {
                        selectedFilterValueData.splice(valIndex, 1)
                      } else {
                        selectedFilterValueData.push(val)
                      }
                      setSelectedFilter(selectedFilterValueData)
                    }}
                    EmptyStateComponent={() => (
                      <EmptyComponent
                        filterInfo={props?.filterInfo}
                        showSearchLoader={showSearchLoader}
                      />
                    )}
                  />
                  <ButtonGroup>
                    <NeoSecondaryButton
                      isDisabled={selectedFilter?.length < 1}
                      onClick={() => {
                        if (props.onChangeCallback) {
                          props.onChangeCallback({
                            [filterKey]: null
                          })
                          props.closeCallback()
                        }
                      }}
                    >
                      Clear
                    </NeoSecondaryButton>
                    <NeoPrimaryButton
                      isDisabled={selectedFilter?.length < 1}
                      onClick={() => {
                        if (props.onChangeCallback) {
                          const selectedValues = selectedFilter?.map(
                            (a) => a.value
                          )
                          props.onChangeCallback({
                            [filterKey]: selectedValues
                          })
                          props.closeCallback()
                        }
                      }}
                    >
                      Apply
                    </NeoPrimaryButton>
                  </ButtonGroup>
                </div>
              </div>
            </Container>
          </div>
        </Intl.IntlProvider>
      </NeoThemeProvider>
    </NeoErrorBoundary>
  )
}
