import NeoErrorBoundary from "@neo/neo-common-ui/lib/components/neo-error-boundary"
import NeoThemeProvider from "@neo/neo-common-ui/lib/components/neo-theme/NeoThemeProvider"
import { Intl, messages } from "../../forcepoint-platform-utilityui"
import styled from "styled-components"
import { useEffect, useState } from "react"
import {
  NeoPrimaryButton,
  NeoRadioButton,
  NeoSecondaryButton
} from "@neo/neo-common-ui/lib"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  gap: 8px;
`

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  width: 100%;
`

function RadioFilterComponent(props) {
  /**
   * Holds the value to be filtered on, selected by the user
   */
  const [filterVal, setFilterVal] = useState("")
  /**
   * Populates the filter options if value already exists
   */
  useEffect(() => {
    if (props?.filterInfo?.additionalInfo?.value) {
      setFilterVal(props.filterInfo.additionalInfo.value)
    }
  }, [props?.filterInfo?.additionalInfo?.value])

  const filterKey = props.filterInfo?.columnKey;
  const updateSelectedFilter = () => {
    if (props.onChangeCallback) {
      props.onChangeCallback({ [filterKey]: filterVal })
      props.closeCallback()
    }
  }

  const clearFilterValue = () => {
    setFilterVal(undefined);
  }
  return (
    <NeoErrorBoundary>
      <NeoThemeProvider>
        <Intl.IntlProvider locale={"en"} messages={messages["en"]}>
          <div className="table-custom-filter">
            <Container>
              <div>
                {props?.filterInfo?.additionalInfo?.type === "radio" ? (
                  <div className="d-flex align-items-center input-suffix">
                    <div className="d-flex flex-row gap justify-content-center align-items-center">
                      {props?.filterInfo?.additionalInfo?.radioBtnOptions?.map(
                        (option) => {
                          return (
                            <NeoRadioButton
                              key={option.value}
                              style={{ paddingBottom: "1rem", scale: "0.9" }}
                              label={option.text}
                              name={option.text}
                              value={option.value}
                              onClick={(e) => setFilterVal(e.value)}
                              checked={option.value === filterVal}
                            />
                          )
                        }
                      )}
                    </div>
                    {/* {props?.filterInfo?.additionalInfo?.desc && <div className="info-tooltip">
                        <NeoIcon
                            fill="#00af9a"
                            name="outline_info"
                            data-always="true"
                            data-font-size="16px"
                            data-dark-html-tip={props.filterInfo.additionalInfo.desc}
                            data-dark-html-tip-at="left"
                            data-line-height="18px"
                            data-margin="2px"
                            data-padding="8px 12px"
                            data-white-space="nowrap"
                            data-word-break="normal"
                            onClick={function noRefCheck() { }
                            }
                            size={12}
                        />
                    </div>} */}
                  </div>
                ) : null}
              </div>
              <ButtonGroup>
                <NeoSecondaryButton
                  onClick={() => clearFilterValue()}
                  isDisabled={!props?.filterInfo?.additionalInfo?.value && !filterVal}
                >
                  Clear
                </NeoSecondaryButton>
                <NeoPrimaryButton
                  onClick={() => updateSelectedFilter()}
                // isDisabled={!filterVal || filterVal.length === 0}
                >
                  Apply
                </NeoPrimaryButton>
              </ButtonGroup>
            </Container>
          </div>
        </Intl.IntlProvider>
      </NeoThemeProvider>
    </NeoErrorBoundary>
  )
}

export default RadioFilterComponent
