import { NeoTable } from "@neo/neo-common-ui/lib"
import { useEffect, useState } from "react"
import NeoErrorBoundary from "@neo/neo-common-ui/lib/components/neo-error-boundary"
import NeoThemeProvider from "@neo/neo-common-ui/lib/components/neo-theme/NeoThemeProvider"
import SizeSelectionPagination from "./SizeSelectionPagination"
import NoDataToDisplayTable from "../NoDataToDisplayTable/NoDataToDisplayTable"
import { Intl, messages } from "../../forcepoint-platform-utilityui"
import { TextFilterComponent } from "./TextFilterComponent"
import { SelectedFilterComponent } from "./SelectedFilterComponent"
import { CheckboxFilterComponent } from "./CheckboxFilterComponent"
import { MultiSelectFilterComponent } from "./MultiSelectFilterComponent"
import RadioFilterComponent from "./RadioFilterComponent"
import { Dropdown } from "./Dropdown"
interface PlatfromTableProps {
  tableData: any[]
  tableHeaders: any[]
  isLoading?: boolean
  selectColumns?: boolean
  appPrefix?: any
  listQueryParams: any
  setListQueryParams?: (params: any) => void
  onChangeParam?: (params: any, extraParams?: any) => void
  handleRefresh?: () => void
  totalRecords: number
  onRowClick: any
  onRowRightClick: any
  onColumnResize: any
  pageSizeList: number[]
  pageSize?: number
  filteredColumns?: any
  id?: string
  hidePagination: boolean
  idKey?: string
  isExpanded?: boolean
  style: any
  isSticky: boolean
  styledRowComponent: any
  styledHeaderComponent: any
  activeRowKey: string
  loadingComponent: any
  isStatic: boolean
  onColumnsReorder: any
  trackScrolling: boolean
  testId: string
  emptyCellHyphen: boolean
  pageName?: string
}

/**
 * Holds all the filter components as key value pairs.
 * Key = user selection for filter type.
 * Value = The corresponding filter component.
 */
const filterComponentMap = {
  text: TextFilterComponent,
  select: SelectedFilterComponent,
  checkbox: CheckboxFilterComponent,
  multiselect: MultiSelectFilterComponent,
  radio: RadioFilterComponent,
  dropdown: Dropdown
}

export default function PlatformNeoTable(props: PlatfromTableProps) {
  const { listQueryParams, setListQueryParams } = props

  /**
   * To Filter value
   */
  const [filters, setFilters] = useState({})
  /**
   * To Filter value
   */
  const [filteredColumns, setFilteredColumns] = useState([])

  /**
   * To store page size
   */
  const pageSize = props.pageSize ?? 25

  const initialParams: any = {
    pageNo: 0,
    pageSize: pageSize,
    orderby: [],
    search: undefined,
    refresh: false
  }

  /**
   * To store and set filter keys
   */
  const [filterKeys, setFilterKeys] = useState<any>({})

  /**
   * To store and set headers
   */
  const [headers, setHeaders] = useState([])

  /**
   * To store and set list params
   */
  const [paginationData, setPaginationData] = useState<any>(initialParams)

  /**
   * Filter changed callback
   * @param obj
   */
  const filterChangedCallback = (obj) => {
    if (obj.searchValue) {
      setPaginationData((val) => ({
        ...val,
        pageNo: 0,
        refresh: true,
        filtersExtra: obj
      }))
      return
    }
    const filterObj = { ...filters, ...obj };

    const tmpObj = {}
    const filteredArr = []
    for (const key in filterObj) {
      if (filterObj[key]) {
        tmpObj[key] = filterObj[key]
        filteredArr.push(key)
      }
    }
    setFilteredColumns(filteredArr)
    setFilters(tmpObj)
    setPaginationData((val) => ({
      ...val,
      pageNo: 0,
      filters: tmpObj,
      refresh: true,
      filtersExtra: null
    }))
  }
  /**
   * Tp get sorting
   * @param newSortInfo
   */
  const getSorting = (newSortInfo: any) => {
    const sortInfo = paginationData?.sortInfo ? paginationData?.sortInfo : []
    const elmIndex = sortInfo?.findIndex(
      (element) => element.columnKey === newSortInfo?.[0].columnKey
    )
    const tmpArr = sortInfo ? JSON.parse(JSON.stringify(sortInfo)) : []
    if (elmIndex > -1) {
      if (sortInfo[elmIndex].sortOrder === "DESC") {
        tmpArr.splice(elmIndex, 1)
      } else {
        tmpArr[elmIndex].sortOrder = newSortInfo[0].sortOrder
      }
    } else {
      tmpArr.push(newSortInfo[0])
    }
    const orderby = getOrderbyStringFromSortInfo(tmpArr)
    setPaginationData((val) => ({
      ...val,
      sortInfo: tmpArr,
      orderby,
      refresh: true
    }))
  }

  /**
   *
   * @param pageNo On change of pagination
   */
  const onHandlePagination = (pageNo) => {
    setPaginationData((val) => ({ ...val, pageNo, refresh: true }))
  }

  const getOrderbyStringFromSortInfo = (sortInfo) => {
    if (!sortInfo) {
      return []
    }
    return [...sortInfo].map((val) => {
      const order = `${filterKeys[val.columnKey] ? filterKeys[val.columnKey] : val.columnKey},${val.sortOrder === "ASC" ? 0 : 1}`
      return order
    })
  }
  /**
   * To Refresh list with params
   */
  const refreshListWithParams = () => {
    const paramObj: any = {
      offset: paginationData.pageSize * paginationData.pageNo,
      limit: paginationData.pageSize,
      orderby: paginationData.orderby
    }
    if (filters) {
      for (const key in filters) {
        const filterKey = filterKeys[key] ?? key;
        paramObj[filterKey] = filters[key]
      }
    }
    if (paginationData.search) {
      paramObj.search = paginationData.search
    }
    if (props.onChangeParam) {
      if (setListQueryParams) {
        setListQueryParams(
          structuredClone({
            ...listQueryParams,
            ...paginationData,
            ...paramObj,
            refresh: false
          })
        )
      }
      props.onChangeParam(
        structuredClone(paramObj),
        structuredClone({
          ...listQueryParams,
          ...paginationData,
          ...paramObj,
          refresh: false
        })
      )
    }
  }

  /**
   * Table headers and filtered columns effect
   */
  useEffect(() => {
    if (props.tableHeaders && props.tableHeaders.length > 0) {
      const obj1: any = {}
      for (const item of props.tableHeaders) {
        obj1[item.key] = item.filterKey
      }
      setFilterKeys(obj1)
      setHeaders(
        props.tableHeaders?.map((val: any) => {
          const obj2 = { ...val }
          if (obj2.filterType && !obj2.filterAdditionalInfo) {
            obj2.filterAdditionalInfo = {
              label: obj2.title,
              type: obj2.filterType
            }
          }
          if (obj2.filterType && filters?.[obj2.key]) {
            obj2.filterAdditionalInfo.value = filters?.[obj2.key]
            obj2.filterAdditionalInfo.searchValue = filters?.[obj2.key]
          }
          if (obj2.filterType && obj2.filterKey) {
            obj2.filterAdditionalInfo.filterKey = obj2.filterKey;
          }
          obj2.filterChangedCallback = filterChangedCallback
          obj2.filterComponent = filterComponentMap[obj2.filterType]
          return obj2
        })
      )
    } else {
      setHeaders([])
    }
  }, [props.tableHeaders, filteredColumns])

  useEffect(() => {
    if (paginationData.refresh) {
      refreshListWithParams()
      setPaginationData((val) => ({ ...val, refresh: false }))
    }
  }, [paginationData])

  const isParamObjChanged = (params, pagination) => {
    const offset = pagination.pageSize * pagination.pageNo
    if ((params.offset || offset) && params.offset !== offset) {
      return true
    }
    if (
      (params.limit || pagination.pageSize) &&
      params.limit !== pagination.pageSize
    ) {
      return true
    }
    if (
      (params.search || pagination.search) &&
      params.search !== pagination.search
    ) {
      return true
    }
    if (params.refresh) {
      return true
    }
    return false
  }
  useEffect(() => {
    if (listQueryParams) {
      const queryParams = { ...listQueryParams }
      if (props.totalRecords > 0) {
        const availablePages = Math.ceil(props.totalRecords / queryParams.limit)
        if (paginationData.pageNo > availablePages - 1) {
          queryParams.offset = (availablePages - 1) * queryParams.limit
          queryParams.pageNo = availablePages - 1
        }
      }
      const flag = isParamObjChanged(queryParams, paginationData)

      if (flag) {
        setPaginationData((val) => {
          const obj: any = { ...val, ...queryParams }
          obj.refresh = flag
          if (obj.resetPagination) {
            obj.pageNo = 0
            // obj.pageSize = pageSize
            delete obj.resetPagination
            obj.filters = []
            obj.sortInfo = []
            setFilters({})
            setFilteredColumns([])
          }
          if (!obj.sortInfo) {
            obj.sortInfo = []
          }
          if (queryParams.search) {
            obj.pageNo = 0
          } else {
            obj.search = undefined
          }
          if (!obj.filters) {
            obj.filters = []
          }
          return obj
        })
      } else {
        setPaginationData((val) => ({ ...val, refresh: false }))
      }
    } else {
      setPaginationData((val) => ({ ...val, refresh: true }))
    }
  }, [listQueryParams, props.totalRecords])

  /**
   * UI Template
   */
  return (
    <NeoErrorBoundary>
      <NeoThemeProvider>
        <Intl.IntlProvider locale={"en"} messages={messages["en"]}>
          <div className="w-100 h-100">
            <NeoTable
              id={props?.id ? props.id : "custom-table"}
              columns={headers}
              rows={props.tableData}
              style={props.style}
              isSticky={props.isSticky}
              idKey={props.idKey || "id"}
              styledRowComponent={props.styledRowComponent}
              styledHeaderComponent={props.styledHeaderComponent}
              activeRowKey={props.activeRowKey}
              sortInfo={paginationData?.sortInfo ? paginationData.sortInfo : []}
              sortCallback={getSorting}
              filteredColumns={filteredColumns}
              noRowComponent={<NoDataToDisplayTable />}
              loadingComponent={props.loadingComponent}
              rowClickedHandler={({ record }) =>
                props.onRowClick && props.onRowClick(record)
              }
              rowRightClickedHandler={({ record }) =>
                props.onRowRightClick && props.onRowRightClick(record)
              }
              isLoading={props.isLoading}
              isStatic={props.isStatic}
              emptyCellHyphen={props.emptyCellHyphen || true}
              selectColumns={props.selectColumns}
              isExpanded={props.isExpanded || false}
              onColumnResize={({ column, calculatedWidth, event }) =>
                props.onColumnResize &&
                props.onColumnResize(column, calculatedWidth, event)
              }
              onColumnsReorder={(data) =>
                props.onColumnsReorder && props.onColumnsReorder(data)
              }
              trackScrolling={props.trackScrolling}
              testId={props.testId}
              footerComponent={
                <>
                  {!props.hidePagination ? (
                    <SizeSelectionPagination
                      pageIndex={paginationData.pageNo}
                      pageSize={paginationData.pageSize}
                      totalItems={props.totalRecords ?? 0}
                      pageChangeHandler={onHandlePagination}
                      pageSizeChangeHandler={(pageSize) => {
                        setPaginationData((val) => ({
                          ...val,
                          pageSize,
                          pageNo: 0,
                          refresh: true
                        }))
                      }}
                      isLoading={props.isLoading}
                      refreshCallback={refreshListWithParams}
                      pageSizeList={props.pageSizeList}
                    />
                  ) : null}
                </>
              }
            />
          </div>
        </Intl.IntlProvider>
      </NeoThemeProvider>
    </NeoErrorBoundary>
  )
}
